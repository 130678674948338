import ChoiceModal from "@/components/locations/importExportWizard/choiceModal/ChoiceModal.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { HttpQuery } from "@/interfaces/httpQuery";
import { Strain } from "@/interfaces/strain";
import { pusherEvents } from "@/internal";
import { StrainTableMetadata } from "@/metadata/strain";
import { INITIAL_QUERY_STATE } from "@/services/http.service";
import { strainActionService } from "@/services/strain.action.service";
import { strainService } from "@/services/strain.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./StrainsList.template.vue";
const namespace: string = "StrainModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(strainService)
export default class StrainsListComponent extends Vue {
  @Getter("strains", { namespace })
  public strains!: Strain[];
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("loadStrains", { namespace })
  public getStrainsAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public headers: TableHeader[] = StrainTableMetadata;
  public showFilters = false;
  public activeFilter = false;
  public enableMultiActions = false;
  public multiActions: TableAction[] = strainActionService.getMultiActions();
  public rowActions: TableAction[] = strainActionService.getRowActions();
  public generalActions: ActionSubheader[] = strainActionService.getGeneralActions(
    this.openCsvModal,
    this.filtersCallback
  );

  protected query: HttpQuery = {
    ...INITIAL_QUERY_STATE
  };

  public openCsvModal() {
    this.$modals.load(
      ChoiceModal,
      {
        size: "fit",
        positionY: "top"
      },
      { csvType: "strains" }
    );
  }

  public changePagination(pagination: TablePagination) {
    const paginate = strainService.paginationAction();
    paginate(pagination);
  }

  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.triggerSearch({ "q[is_active]": 1 });
    }
  }

  /**
   * Triggers search for qualified/non qualified products.
   * @param header: TableHader
   */
  public onStrainsListFilter(active: boolean) {
    this.activeFilter = active;
    const param = active
      ? { "q[is_active]": 1 }
      : { "q[is_active_is_false]": 1 };
    this.triggerSearch(param);
  }

  public filter(header: TableHeader) {
    const aux = cloneDeep(header);
    if (aux.value === "strain_type.name") {
      aux.value = "strainType.name";
    }
    strainService.sortQuery(aux);
  }

  public async triggerSearch(query?: HttpQuery) {
    strainService.setQuery(query);
    this.getStrainsAction();
  }

  protected mounted() {
    this.enableMultiActions = this.hasPermission(
      policyList.enableDisableStrains
    );
    this.setPageNav({
      title: "strain.strains",
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    this.triggerSearch({ ...this.query, "q[is_active]": 1 });
    this.$changes.watch(pusherEvents.strainTouched, this.getStrainsAction);
  }

  protected beforeDestroy() {
    strainService.resetQuery();
  }
}
